import React, { useState, useEffect, useContext } from "react";
import { RootState } from "../../controllers/store";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { AppThunk } from "../../controllers/store";

import config from '../../configuration';
import { CmsComponent, CmsField, CmsDataCache, CmsFieldTypes } from 'crownpeak-dxm-react-sdk';
import UserContext, { UserProvider } from '../../providers/user-provider';
import { useMsal } from "@azure/msal-react";
import { allowedNodeEnvironmentFlags } from "process";


export default class SmartSearch extends CmsComponent {

  addtoCartURL: any;
  moreInfoURL: any;

  partNumberLabel: any;
  addtoCartLabel: any;
  moreInfoLabel: any;
  noSearchMatchLabel: any;

  uploadLabel: any;
  uploadAreaDesktopText: any;
  uploadAreaMobileText: any;
  uploadBtnText: any;
  uploadImage: any;
  uploadAlt: any;

  uploadSuccessAlertImg: any;
  uploadSuccessAlertImgAlt: any;
  uploadErrorAlertImg: any;
  uploadErrorAlertImgAlt: any;

  constructor(props) {
    super(props);
    this.cmsFolder = "Section";
    this.cmsDisableDragDrop = false;
    this.cmsZones = ["droppable", "DragDropZone"];

    
    this.addtoCartURL = new CmsField("AddToCart_URL", CmsFieldTypes.TEXT, props && props.data ? props.data.AddToCart_URL : null);
    this.moreInfoURL = new CmsField("MoreInfo_URL", CmsFieldTypes.TEXT, props && props.data ? props.data.MoreInfo_URL : null);

    this.partNumberLabel = new CmsField("PartNumber_Label", CmsFieldTypes.TEXT, props && props.data ? props.data.PartNumber_Label : null);
    this.addtoCartLabel = new CmsField("AddToCart_Label", CmsFieldTypes.TEXT, props && props.data ? props.data.AddToCart_Label : null);
    this.moreInfoLabel = new CmsField("MoreInfo_Label", CmsFieldTypes.TEXT, props && props.data ? props.data.MoreInfo_Label : null);
    this.noSearchMatchLabel = new CmsField("NoSearchMatchLabel", CmsFieldTypes.TEXT, props && props.data ? props.data.NoSearchMatchLabel : null);


    this.uploadLabel = new CmsField("Upload_Label", CmsFieldTypes.TEXT, props && props.data ? props.data.Upload_Label : null);
    this.uploadImage = new CmsField("Upload_Image", CmsFieldTypes.IMAGE, props && props.data ? props.data.Upload_Image : null);
    this.uploadAlt = new CmsField("Upload_Image_Alt", CmsFieldTypes.TEXT, props && props.data ? props.data.Upload_Image_Alt : null);
    this.uploadBtnText = new CmsField("Upload_Button_Text", CmsFieldTypes.TEXT, props && props.data ? props.data.Upload_Button_Text : null);

    this.uploadSuccessAlertImg = new CmsField("Upload_Success_Alert_Image", CmsFieldTypes.IMAGE, props && props.data ? props.data.Upload_Success_Alert_Image : null);
    this.uploadSuccessAlertImgAlt = new CmsField("Upload_Success_Alert_Image_Alt", CmsFieldTypes.TEXT, props && props.data ? props.data.Upload_Success_Alert_Image_Alt : null);
    
    this.uploadErrorAlertImg = new CmsField("Upload_Error_Alert_Image", CmsFieldTypes.IMAGE, props && props.data ? props.data.Upload_Error_Alert_Image : null);
    this.uploadErrorAlertImgAlt = new CmsField("Upload_Error_Alert_Image_Alt", CmsFieldTypes.TEXT, props && props.data ? props.data.Upload_Error_Alert_Image_Alt : null);

    this.uploadAreaDesktopText = new CmsField("Upload_Area_Desktop_Text", CmsFieldTypes.TEXT, props && props.data ? props.data.Upload_Area_Desktop_Text : null);
    this.uploadAreaMobileText = new CmsField("Upload_Area_Mobile_Text", CmsFieldTypes.TEXT, props && props.data ? props.data.Upload_Area_Mobile_Text : null);
  }


  render() {
    return (
        <>
          {/* cp-scaffold
            {AddToCart_URL:Text}
            {MoreInfo_URL:Text}

            {PartNumber_Label:Text}
            {AddToCart_Label:Text}
            {MoreInfo_Label:Text}
            {NoSearchMatchLabel:Text}

            <FileUpload
              uploadLabel={Upload_Label:Text}
              uploadImage={Upload_Image:Src}
              uploadAlt={Upload_Image_Alt:Text}
              uploadBtnText={Upload_Button_Text:Text}
              uploadSuccessAlertImg={Upload_Success_Alert_Image:Src}
              uploadSuccessAlertImgAlt={Upload_Success_Alert_Image_Alt:Text}
              uploadErrorAlertImg={Upload_Error_Alert_Image:Src}
              uploadErrorAlertImgAlt={Upload_Error_Alert_Image_Alt:Text}
              uploadAreaDesktopText={Upload_Area_Desktop_Text:Text}
              uploadAreaMobileText={Upload_Area_Mobile_Text:Text}
            />

          /cp-scaffold */}

          <SmartSearchList
            addtoCartURL={this.addtoCartURL}
            moreInfoURL={this.moreInfoURL}

            partNumberLabel={this.partNumberLabel}
            addtoCartLabel={this.addtoCartLabel}
            moreInfoLabel={this.moreInfoLabel}
            noSearchMatchLabel={this.noSearchMatchLabel}

            uploadLabel={this.uploadLabel}
            uploadImage={this.uploadImage}
            uploadAlt={this.uploadAlt}
            uploadBtnText={this.uploadBtnText}
            uploadSuccessAlertImg={this.uploadSuccessAlertImg}
            uploadSuccessAlertImgAlt={this.uploadSuccessAlertImgAlt}
            uploadErrorAlertImg={this.uploadErrorAlertImg}
            uploadErrorAlertImgAlt={this.uploadErrorAlertImgAlt}
            uploadAreaDesktopText={this.uploadAreaDesktopText}
            uploadAreaMobileText={this.uploadAreaMobileText}
          />
        </>
      )
    }
}

export const SmartSearchList = ({
  addtoCartURL,
  moreInfoURL,

  partNumberLabel,
  addtoCartLabel,
  moreInfoLabel,
  noSearchMatchLabel,
  
  uploadLabel,
  uploadImage,
  uploadAlt,
  uploadBtnText,
  uploadAreaDesktopText,
  uploadAreaMobileText,
  uploadSuccessAlertImg,
  uploadSuccessAlertImgAlt,
  uploadErrorAlertImg,
  uploadErrorAlertImgAlt

}) => {

  const [loading, setLoading ] = useState(-1);
  const [partsList, setPartsList ] = useState([]);
  const { runMode } = config;
  const catalog = runMode === 'kvg' ? 'kverneland' : 'vicon';


  function Search(file) {
    setLoading(1);
    setPartsList([]);

      const formdata = new FormData();
      formdata.append("image", file);

      var myHeaders = new Headers();

      myHeaders.append("Accept", "application/offers.complete+json");
      
      if(runMode === 'kvg') {
        myHeaders.append("X-API-KEY", "GCbkPxVictbOBTP5oXdELLSoGAnagIXg");
      }
      else {
        myHeaders.append("X-API-KEY", "8ZDpEPj8qUSh3TKYvr00DojTjpCjGtQk");
      }

      fetch(`https://api.nyris.io/find/v1.1`, {
          method: 'post',    
          headers: myHeaders,
          body: formdata
      })
      .then(response => response.text())
      .then(response => {
        var responseResult = JSON.parse(response);
        setPartsList(responseResult.results);

        setLoading(0);
      })
      .catch(error => 
        {
          setLoading(0);
          console.log('error', error);
        });
  }

  return (
    <>
      <div className="field field-textarea">
        <FileUpload
          uploadLabel={uploadLabel}
          uploadImage={uploadImage}
          uploadAlt={uploadAlt}
          uploadBtnText={uploadBtnText}
          uploadSuccessAlertImg={uploadSuccessAlertImg}
          uploadSuccessAlertImgAlt={uploadSuccessAlertImgAlt}
          uploadErrorAlertImg={uploadErrorAlertImg}
          uploadErrorAlertImgAlt={uploadErrorAlertImgAlt}
          uploadAreaDesktopText={uploadAreaDesktopText}
          uploadAreaMobileText={uploadAreaMobileText}

          fileuploadFunc={Search}
        />
      </div>

        {
          loading == 1? <div className="loader"></div>
          :
          loading == 0 && partsList.length == 0?
          <div className="alert alert-secondary text-center" role="alert">
            {noSearchMatchLabel}
          </div>
          :
          <div className="grid-card-wrapper">
            <div className="d4">
              {
                partsList.map((partItem, index, array) => {

                  var _addtoCartURL = (addtoCartURL && partItem["sku"])? 
                    addtoCartURL?.replaceAll("{sku}", partItem["sku"])
                    .replaceAll("{catalog}", catalog)
                    .replaceAll("{langName}", window.localStorage.lang.split('-')[0])
                     : "";

                     
                  var _moreInfoURL = (moreInfoURL && partItem["sku"])? 
                    moreInfoURL?.replaceAll("{sku}", partItem["sku"])
                    .replaceAll("{catalog}", catalog)
                    .replaceAll("{langName}", window.localStorage.lang.split('-')[0])
                    : "";

                  return (
                    <div className='vertical-card-link-wrapper'>
                      <div className="vertical-card-wrapper">
                          <div className="vertical-card-content">
                              <div className="mh-300">
                                  <img src={partItem["image"]} alt={partItem["score"]} />
                              </div>
                          </div>

                          <div className="vertical-card-link" role="group">
                              <div className="vertical-card-text">
                                  {partItem["description"] && <p className="vertical-card-text-header text-center">{partItem["description"]}</p>}
                                  <p className="vertical-card-text-desc text-center">{partNumberLabel} {partItem["sku"]}</p>
                              </div>

                              <div className="btn-group grey-btn btn-container">
                                {_addtoCartURL != "" &&
                                  <a href={_addtoCartURL} target="_blank">
                                    <button type='button' className="btn btn-component mr-1">{addtoCartLabel}</button>
                                  </a>
                                }

                                {moreInfoLabel != "" &&
                                  <a href={_moreInfoURL} target="_blank">
                                    <button type='button' className="btn btn-component">{moreInfoLabel}</button>
                                  </a>
                                }
                              </div>
                          </div>
                      </div>
                    </div>
                  )
                }
              )}
            </div>
        </div>
        }
    </>
  )
}


export const PRODUCT_FILE_SET = "PRODUCT_FILE_SET";
export const PRODUCT_HELP_FILES = "PRODUCT_HELP_FILES";
export const PRODUCT_FILE_SUBMIT = "PRODUCT_FILE_SUBMIT";
export const PRODUCT_FILE_SUBMITTED = "PRODUCT_FILE_SUBMITTED";


export const FileUpload = ({
  uploadLabel,
  uploadImage,
  uploadAlt,
  uploadBtnText,
  uploadAreaDesktopText,
  uploadAreaMobileText,
  uploadSuccessAlertImg,
  uploadSuccessAlertImgAlt,
  uploadErrorAlertImg,
  uploadErrorAlertImgAlt,

  fileuploadFunc

}) => {
  const [fileHasError, setFileHasError] = useState(false);
  const [field, setField] = useState({
    uploadField: uploadAreaMobileText,
    dropzoneField: uploadAreaDesktopText,
    hasFile: false
  });
  const { user } = useContext(UserContext);
  const { fleetProduct, setFleetProduct } = useContext(UserContext);
  const match = { params: { id: fleetProduct } };
  const { value, fileDone, fileResult, saving, product, helpFiles } =
    useSelector(
      (state: RootState) => ({
        value: state.fleet.details.file,
        fileDone: state.fleet.details.fileDone,
        fileResult: state.fleet.details.fileResult,
        saving: state.fleet.details.saving,
        product: state.fleet.details.product,
        helpFiles: state.fleet.details.helpFiles,
      }),
      shallowEqual
    );
  const { accounts, accessToken } = useContext(UserContext);

  const dispatch = useDispatch();
  const callSetFile = (ev: any): void => {
    ev.preventDefault();
    if (ev && ev.target.files[0] && fileuploadFunc) {
      fileuploadFunc(ev.target.files[0]);
    }
  };
 

  function changeLabel(event) {
    if(event.target.files && event.target.files.length) {
      
      var fileSize = event.target.files[0].size / 1024 / 1024; // in MiB
      if(fileSize > 3 || !(/\.(pbf|png|jpg|jpeg)$/i).test(event.target.value)){
        setFileHasError(true);
      }
      else{
        setFileHasError(false);
        setField({
          uploadField: event.target.files[0].name,
          dropzoneField: event.target.files[0].name,
          hasFile: true
        });
      }
    }    
  }

  return (
    <div className="form-wrapper field-align-c">
      <div className="field-upload field-w50">
        <label className="field-upload-label field-align-c">{uploadLabel}</label>
        
        <div className="field-upload-wrapper">
          <label
            className="field-upload-btn d-none"
            htmlFor="field-upload"
            onChange={(ev) => {
              changeLabel(ev);
              callSetFile(ev);
            }}
            id="field-upload-label"
          >
            {field.uploadField}
            <input type="file" id="field-upload" />
          </label>

          <label
            className="field-drop-btn d-lg-flex"
            htmlFor="field-upload-dropzone"
            onChange={(ev) => {
              changeLabel(ev);
              callSetFile(ev);
            }}
            id="field-upload-dropzone-label">

            <div>
              <img src={uploadImage} alt={uploadAlt} />
            </div>
            {field.dropzoneField}
            <input
              type="file"
              id="field-upload-dropzone"
              draggable
              className="field-upload-dropzone d-none"
            />
          </label>
          <div className="field field-submit">
            {/* <button
              type="submit"
              name="fieldsubmit"
              disabled={!field.hasFile || saving}
              onClick={(e): void => callSubmitFile(e, match.params.id)}
            >
              {saving ? (
                <div className="loader btn-loader"></div>
              ) : (
                uploadBtnText
              )}
            </button> */}
          </div>
        </div>
        {/* <label className={fileHasError? "field-error" : ""}>{uploadNote}</label> */}
      </div>

      {fileDone && fileResult ? (
        <div className="alert-wrapper upload-alert-wrapper">
          <div>
            {fileResult.severity === "0" ? (
              <img src={uploadSuccessAlertImg} alt={uploadSuccessAlertImgAlt} />
            ) : (
              <img src={uploadErrorAlertImg} alt={uploadErrorAlertImgAlt} />
            )}
          </div>
          <p>{fileResult.message}</p>
        </div>
      ) : null}
    </div>
  );
};

