import React, { useState, useContext } from 'react'
import { CmsComponent, CmsDynamicDataProvider, CmsStaticDataProvider } from 'crownpeak-dxm-react-sdk';
import UserContext, { UserProvider } from '../../providers/user-provider';
import HeaderRouting from '../../core/headerRouting';
import { useIsAuthenticated } from '@azure/msal-react';
import { Link } from 'react-router-dom';
import Routing from '../../core/routing';
import moment from 'moment';

export default class HeaderNavigation extends CmsComponent {
    left_nav_items: any[];
    countries_nav_items: any[];
    account_nav_items: any[];
    right_nav_items: any[];
    header_logo: string;
    header_logo_link: string;
    state: any;
    assetId: any;
    account_icon: any;
    mobile_nav_items: any[];
    account_header: any;
    jsonData: any;
    language: any;

    notification_header: any;
    notification_viewall_header: any;
    notification_viewall_link: any;

    headerwarning_show: any;
    headerwarning_title: any;
    headerwarning_description: any;

    constructor(props: any) {
        super(props);
        this.cmsFolder = "Global";
        this.cmsDisableDragDrop = true;
        this.assetId = HeaderRouting.getLanguage(localStorage.getItem('lang') || Routing.languages.filter(item => item === window.location.pathname.split('/')[1])[0] || 'en-ww');

        this.left_nav_items = [];
        this.countries_nav_items = [];
        this.account_nav_items = [];
        this.right_nav_items = [];
        this.mobile_nav_items = [];
        this.header_logo = '';
        this.header_logo_link = '';
        this.account_header = '';
        this.notification_header = '';
        this.notification_viewall_header = '';
        this.notification_viewall_link = '';

        
        this.headerwarning_show = '';
        this.headerwarning_title = '';
        this.headerwarning_description = '';

        this.state = {
            user: {} as any,
            country: ''
        }

        if (this.props.dataProvider instanceof CmsStaticDataProvider) {
            this.jsonData = new CmsStaticDataProvider()?.getCustomDataSync(`${this.assetId}.json`).HeaderNavigationAdmin;
        }
        else {
            this.jsonData = new CmsDynamicDataProvider()?.getDynamicQuerySync(`q=${this.assetId}&fl=custom_t_json:[json]`).response.docs[0].custom_t_json.HeaderNavigationAdmin;
        }

        this.left_nav_items = this.jsonData.LeftNavItems;
        this.countries_nav_items = this.jsonData.CountriesNavItems;
        this.account_nav_items = this.jsonData.AccountNavItems;
        this.right_nav_items = this.jsonData.RightNavItems;
        this.mobile_nav_items = this.jsonData.MobileNavItems;
        this.header_logo = this.jsonData.Header_Logo;
        this.header_logo_link = this.jsonData.Header_Logo_Link;
        this.account_icon = this.jsonData.Account_Icon;
        this.account_header = this.jsonData.Account_Header;

        this.notification_header = this.jsonData.Notification_Header;
        this.notification_viewall_header = this.jsonData.Notification_ViewAll_Header;
        this.notification_viewall_link = this.jsonData.Notification_ViewAll_Link;

        this.headerwarning_show = this.jsonData.Headerwarning_Show;
        this.headerwarning_title = this.jsonData.Headerwarning_Title;
        this.headerwarning_description = this.jsonData.Headerwarning_Description;

        this.language = localStorage.getItem('lang') || Routing.languages.filter(item => item === window.location.pathname.split('/')[1])[0] || 'en-ww';
    }

    static contextType = UserContext;
    componentDidMount() {
        const user = this.context;
        this.setState({
            user: user,
            country: document.querySelector(`.countries .dropdown-item[href$=${localStorage.getItem('lang') || Routing.languages.filter(item => item === window.location.pathname.split('/')[1])[0] || 'en-ww'}]`)?.textContent
        });
    }

    render() {
        
        let i = 0;
        return (
            <div className='full-header'>
                <HeaderWarning 
                    show={this.headerwarning_show}
                    title={this.headerwarning_title}
                    description={this.headerwarning_description}
                    />

                <div className='main-header'>
                    {/* cp-scaffold
                            {headerNavigationPreview:HeaderNavigationPreview}
                        else */}
                        
                    <nav className="navbar navbar-expand-lg navbar-light" id="navbar">
                        {this.header_logo_link.includes('https') ?
                            <a href={this.header_logo_link} className="nav-logo" target="_blank">
                                <img src={this.header_logo} />
                            </a>
                            : <Link to={this.header_logo_link} className="nav-logo">
                                <img src={this.header_logo} />
                            </Link>}

                        <CountriesNav mobView={true} language={this.language} country countriesNavItems={this.countries_nav_items} />
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation" data-parent="#navbar">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">

                            <div className="nav-first-links d-none d-lg-flex">

                                {this.left_nav_items.map((item, index) => {
                                    return (
                                        item.Link.Url.includes('https') ?
                                            <a href={item.Link.Url} className="nav-link" role="menuitem" target="_blank" key={'left_nav_items_' + index}>{item.Link.Title}</a>
                                            : <Link to={item.Link.Url} className="nav-link" role="menuitem" key={'left_nav_items_' + index}>{item.Link.Title}</Link>)
                                })}

                            </div>

                            <div className="nav-first-links d-lg-none">
                                <MobNavItems navItems={this.mobile_nav_items} />
                            </div>

                            <div className="nav-second-links d-none d-lg-flex">
                                <CountriesNav mobView={false} language={this.language} country={this.state.country} countriesNavItems={this.countries_nav_items} />
                                <MyAccountBtn
                                    account_icon={this.account_icon}
                                    account_nav_items={this.account_nav_items}
                                    account_header={this.account_header}
                                />

                                <div className="nav-first-links">
                                    <RightNavItems navItems={this.right_nav_items} />
                                </div>

                            </div>

                            {this.notification_header && this.notification_viewall_link && <MyNotificationsBtn 
                                notification_header={this.notification_header}
                                notification_viewall_header={this.notification_viewall_header}
                                notification_viewall_link={this.notification_viewall_link}
                            />}
                            
                        </div>
                    </nav>
                    {/* /cp-scaffold */}
                </div>
            </div>
        )
    }
}

function CountriesNav({ mobView, language, country, countriesNavItems }): JSX.Element | null {

    const isAuthenticated = useIsAuthenticated();
    const { user, logOut, setLanguageLocal } = useContext(UserContext);

    if (!isAuthenticated) {
        if (mobView) {
            return (
                <div className="dropdown countries d-lg-none mob-dropdown">
                    <button className="nav-link countries-list dropdown-toggle" id="navbarDropdownCountries" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-parent="#navbar">
                        <p>{language}</p>
                        <span className='dropdown-arrow'><i className="arrow down"></i></span>
                    </button>
                    <div className="dropdown-menu">
                        <div className="dropdown-menu-list">
                            {countriesNavItems.map((item, index) => {
                                return <Link className="dropdown-item" to={item.DropdownLink?.Url} role="menuitem" key={'country_nav_items_' + index} onClick={(e) => { e.preventDefault(); setLanguageLocal(item.DropdownLink?.Url); }}>{item.DropdownLink?.Title}</Link>
                            })}
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="dropdown countries d-none d-lg-inline-block">
                <Link to="#" className="nav-link countries-list dropdown-toggle" id="navbarDropdownCountries" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <p>{language}</p>
                    <span>{country}</span>
                    <span className='dropdown-arrow'><i className="arrow down"></i></span>
                </Link>
                <div className="dropdown-menu">
                    <div className="dropdown-menu-list">
                        {countriesNavItems.map((item, index) => {
                            return <Link className="dropdown-item" to={item.DropdownLink?.Url} role="menuitem" key={'country_nav_items_' + index} onClick={(e) => { e.preventDefault(); setLanguageLocal(item.DropdownLink?.Url); }}>{item.DropdownLink?.Title}</Link>
                        })}
                    </div>
                </div>
            </div>
            )
        }
    } else {
        return null;
    }

}

function HeaderWarning({show, title, description}){
    let isheaderwarning_show = false;
    if(show && localStorage.getItem("headerwarning_" + (new Date()).getDate()) != "hide")
     {
        var c = show.toLowerCase()[0];
        if(c == 't' || c  == 'y') { isheaderwarning_show = true; }
     }

     
     if (isheaderwarning_show) {
        return (
            <div className={'warning-header' + (isheaderwarning_show? ' d-block' : '')}>

                <svg className='warning-header-icon' xmlns="http://www.w3.org/2000/svg" width="30.154" height="28" viewBox="0 0 30.154 28">
                    <path id="Icon_ionic-md-warning" data-name="Icon ionic-md-warning" d="M2.25,31.375H32.4l-15.077-28Zm16.692-4.308H15.712V23.837h3.231Zm0-5.385H15.712V15.221h3.231Z" transform="translate(-2.25 -3.375)" fill="#ff434a"/>
                </svg>

                <span dangerouslySetInnerHTML={{ __html: title }}></span>
                <div dangerouslySetInnerHTML={{ __html: description }}></div>

                <a className='warning-header-close' onClick={(e) => {
                            
                    e.preventDefault();
                    localStorage.setItem("headerwarning_" + (new Date()).getDate(), "hide");
                    window.location.reload();
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16.672" height="16.672" viewBox="0 0 16.672 16.672">
                        <path id="Icon_material-close" data-name="Icon material-close" d="M22.758,9.037,21.221,7.5l-6.092,6.092L9.037,7.5,7.5,9.037l6.092,6.092L7.5,21.221l1.537,1.537,6.092-6.092,6.092,6.092,1.537-1.537-6.092-6.092Z" transform="translate(-6.793 -6.793)" fill="#ff434a" stroke="#ff434a" stroke-width="1"/>
                    </svg>
                </a>
            </div>
        );
    }
    else {
        return null;
    }
}

function MyNotificationsBtn({ notification_header, notification_viewall_header, notification_viewall_link }) {

    const isAuthenticated = useIsAuthenticated();
    const { user, logOut, accounts, notifications } = useContext(UserContext);
    if (isAuthenticated) {
        return (
            <>
                <div className="dropdown nav-account nav-notification d-none d-lg-flex">
                    <a href={notification_viewall_link} className="nav-link nav-account-list dropdown-toggle icon" id="navbarDropdownMyNotification">
                        <span>{notifications.unreadCount}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><g><path d="M10 18.333c.917 0 1.667-.75 1.667-1.666H8.333c0 .916.75 1.666 1.667 1.666zm5-5V9.167c0-2.559-1.358-4.7-3.75-5.267v-.567c0-.691-.558-1.25-1.25-1.25s-1.25.559-1.25 1.25V3.9C6.367 4.467 5 6.6 5 9.167v4.166L3.333 15v.833h13.334V15L15 13.333zm-1.667.834H6.667v-5c0-2.067 1.258-3.75 3.333-3.75s3.333 1.683 3.333 3.75v5z" id="b"></path></g></svg>
                    </a>
                    <div className="dropdown-menu">
                        <div className="dropdown-menu-list dropdown-notification-list">

                            <div className="dropdown-notification-header">
                                <span>{notification_header}</span>
                                <a href={notification_viewall_link} target=''>{notification_viewall_header}</a>
                            </div>
                            {notifications.notifications.map((notification, index, array) => {
                                const tempElement = document.createElement('div');
                                tempElement.innerHTML = notification["content"];

                                return (
                                    <a href={notification_viewall_link + "?ID=" + notification["id"] } className='dropdown-notification-item'>
                                        <h4>{notification["header"]}</h4>
                                        <p>{tempElement.textContent}</p>

                                        <span>{notification["DisplyTime"]}</span>
                                    </a>
                                )
                            })}
                            
                        </div>
                    </div>
                </div>

                <div className='d-lg-none'>
                    <a className='nav-link' href={notification_viewall_link} target='_blank'>{notification_header}</a>
                </div>
            </>
        );
    } else {
        return null;
    }
}

function MyAccountBtn({ account_icon, account_nav_items, account_header }) {

    const isAuthenticated = useIsAuthenticated();
    const { user, logOut } = useContext(UserContext);

    if (isAuthenticated) {
        return (
            <div className="dropdown nav-account">
                <a href="#" className="nav-link nav-account-list dropdown-toggle" id="navbarDropdownMyAccount">
                    <img src={account_icon} />
                    <span>{account_header}</span>
                    <span className='dropdown-arrow'><i className="arrow down"></i></span>
                </a>
                <div className="dropdown-menu">
                    <div className="dropdown-menu-list">

                        {account_nav_items.map((item, index, array) => {
                            if(item.FirstDropdownLink.Url.includes('https')){
                                return(<a className="dropdown-item" href={item.FirstDropdownLink.Url} role="menuitem" target="_blank" key={'account_nav_items_' + index}>{item.FirstDropdownLink.Title}</a>)
                            }
                            else if(item.FirstDropdownLink.Url.includes("#b2c-log-out"))
                            {
                                return (<a className="dropdown-item" href={item.FirstDropdownLink.Url} role="menuitem" key={'account_nav_items_' + index} onClick={(e) => { e.preventDefault(); user.logout(); }}>{item.FirstDropdownLink.Title}</a>)
                            }
                            else if(item.FirstDropdownLink.Url.includes("#b2c-edit_password")){
                                return(<a className="dropdown-item" href={item.FirstDropdownLink.Url} role="menuitem" key={'account_nav_items_' + index} onClick={(e) => { e.preventDefault(); user.editPassword(window.localStorage.lang); }}>{item.FirstDropdownLink.Title}</a>)
                            }
                            else if (item.FirstDropdownLink.Url.includes("#b2c-edit_email")) {
                                return (<a className="dropdown-item" href={item.FirstDropdownLink.Url} role="menuitem" key={'account_nav_items_' + index} onClick={(e) => { e.preventDefault(); user.editEmail(window.localStorage.lang); }}>{item.FirstDropdownLink.Title}</a>)
                            }
                            else if(item.FirstDropdownLink.Url.includes("#b2c-edit-profile")){
                                return(<a className="dropdown-item" href={item.FirstDropdownLink.Url} role="menuitem" key={'account_nav_items_' + index} onClick={(e) => { e.preventDefault(); user.editProfile(window.localStorage.lang); }}>{item.FirstDropdownLink.Title}</a>)
                            }
                            else {
                                return(<Link className="dropdown-item" to={item.FirstDropdownLink.Url} key={'account_nav_items_' + index} role="menuitem">{item.FirstDropdownLink.Title}</Link>)
                            }
                        })}

                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}

function RightNavItems({ navItems }) {
    const { user, isLogedIn } = useContext(UserContext);
    return (
        navItems.map((item, index) => {
            return (
                item.SecondLink.Url.includes('#b2c-login') ?
                    (!isLogedIn ? <a href={item.SecondLink.Url} className="nav-link" role="menuitem" key={'right_nav_items_' + index} onClick={(e) => { e.preventDefault(); user.signIn(window.localStorage.lang) }}>{item.SecondLink.Title}</a> : null)
                    : <Link to={item.SecondLink.Url} className="nav-link" role="menuitem" key={'right_nav_items_' + index}>{item.SecondLink.Title}</Link>
            )
        })
    )
}

function MobNavItems({ navItems }) {
    const isAuthenticated = useIsAuthenticated();
    const { user } = useContext(UserContext);
    return (
        navItems.map((item, index) => {
            if(item.ThirdLink.Url.includes('https')){
                return(<a href={item.ThirdLink.Url} role="menuitem" className="nav-link" target="_blank" key={'site_nav_items_' + index}>{item.ThirdLink.Title}</a>)
            }
            else if(item.ThirdLink.Url.includes('#b2c-edit-profile')){
                return(<a href={item.ThirdLink.Url} className="nav-link" role="menuitem" key={'site_nav_items_' + index} onClick={(e) => { e.preventDefault(); user.editProfile(window.localStorage.lang) }}>{item.ThirdLink.Title}</a>)
            }
            else if(item.ThirdLink.Url.includes('#b2c-edit_password')){
                return(<a href={item.ThirdLink.Url} className="nav-link" role="menuitem" key={'site_nav_items_' + index} onClick={(e) => { e.preventDefault(); user.editPassword(window.localStorage.lang) }}>{item.ThirdLink.Title}</a>)
            }
            else if (item.ThirdLink.Url.includes('#b2c-edit_email')) {
                return (<a href={item.ThirdLink.Url} className="nav-link" role="menuitem" key={'site_nav_items_' + index} onClick={(e) => { e.preventDefault(); user.editEmail(window.localStorage.lang) }}>{item.ThirdLink.Title}</a>)
            }
            else if(item.ThirdLink.Url.includes('#b2c-log-out')){
                return((isAuthenticated ? <a href={item.ThirdLink.Url} className="nav-link" role="menuitem" key={'site_nav_items_' + index} onClick={(e) => { e.preventDefault(); user.logout(); }}>{item.ThirdLink.Title}</a> : null))
            }
            else {
                return(<Link to={item.ThirdLink.Url} role="menuitem" className="nav-link" key={'site_nav_items_' + index}>{item.ThirdLink.Title}</Link>)
            }
        })
    )
}